<template>
  <div class="wheel_wrap" :class="{'has-fixed': state.gotValues}">
    <h1>Eid Salami Generator</h1>

    <Wheel
      ref="wheel"
      :gift="state.gift"
      :data="data"
      :img-params="state.logo"
      @done="done"
      @click="spinTheWheel"
    />

    <div v-if="state.gotValues" class="result-wrapper">
      <div class="card result-form" style="width: 500px">
        <div class="card-header">
          <h3 class="text-start">Congratulations!</h3>
        </div>
        <div class="card-body">

          <h1 class="text-center text-black">You will get TK
            <strong class="fw-bold"> {{ form.amount }} </strong>
            Salami
          </h1>

          <h3
            v-if="['success', 'error'].includes(state.showAlert)"
            class="text-center"
            :class="[
              {'alert alert-success': state.showAlert === 'success'},
              {'alert alert-danger': state.showAlert === 'error'},
            ]"
          >
            {{ state.alertMessage }}
          </h3>

          <h3 v-else>
            Please fill up the form to get your Salami
          </h3>

          <form>
            <div class="mb-3 text-start">
              <label for="name" class="form-label">Name</label>
              <input
                id="name"
                v-model="form.name"
                name="name"
                type="text"
                class="form-control"
                :class="{'is-invalid': !!form.errors.name}"
                placeholder="Name"
              >
              <div v-if="!!form.errors.name" class="invalid-feedback">
                {{ form.errors.name }}
              </div>
            </div>
            <div class="mb-3 text-start">
              <label for="phone" class="form-label">Bkash Number</label>
              <input
                id="phone"
                v-model="form.phone"
                name="phone"
                type="text"
                class="form-control"
                :class="{'is-invalid': form.errors.phone}"
                placeholder="Enter Bkash Number"
              >
              <div v-if="form.errors.phone" class="invalid-feedback">
                {{ form.errors.phone }}
              </div>
            </div>
            <div class="mb-3 text-start">
              <label for="amount" class="form-label">Amount</label>
              <input
                id="amount"
                :value="form.amount"
                name="amount"
                type="text"
                class="form-control"
                :class="{'is-invalid': form.errors.amount}"
                placeholder="Amount"
                disabled
              >
              <div v-if="form.errors.amount" class="invalid-feedback">
                {{ form.errors.amount }}
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer d-flex justify-content-end gap-2">
          <button
            v-if="['success', 'error'].includes(state.showAlert)"
            class="btn btn-primary btn-lg"
            type="button"
            @click="closeModal"
          >
            close
          </button>

          <button
            v-else
            class="btn btn-primary btn-lg"
            type="button"
            @click="resetWheel"
          >
            Try Again
          </button>

          <button
            class="btn btn-primary btn-lg"
            type="button"
            @click="submitForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>


    <div class="footer text-center text-white pt-3 pb-3">
      <p>Eid Salami by <a class="text-white ms-1" href="https://hasanmisbah.com" target="_blank">Hasan Misbah</a></p>
    </div>
  </div>
</template>

<script setup>
import { useLocalStorage, useFirestore } from '@/Composables/composable';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue3-fortune-wheel/dist/library.css';
import { Wheel } from 'vue3-fortune-wheel';
import { reactive, ref, watch } from 'vue';
import { isEmpty } from 'lodash';

const { storage } = useLocalStorage(true);

const { firestore } = useFirestore();

const wheel = ref(null);

const data = ref([
  // {
  //     id: 1,
  //     value: '1',
  //     bgColor: '#7d7db3',
  //     color: '#ffffff',
  // },
  {
    id: 2,
    value: '2',
    bgColor: '#ffffff',
    color: '#000000',
  },
  {
    id: 3,
    value: '5',
    bgColor: '#7d7db3',
    color: '#ffffff',
  },
  {
    id: 4,
    value: '10',
    bgColor: '#a21d46',
    color: '#ffffff',
  },
  {
    id: 5,
    value: '20',
    bgColor: '#ff60a9',
    color: '#ffffff',
  },
  {
    id: 6,
    value: '50',
    bgColor: '#06c122',
    color: '#ffffff',
  },
  {
    id: 7,
    value: '100',
    bgColor: '#9700a7',
    color: '#ffffff',
  },
  {
    id: 8,
    value: '200',
    bgColor: '#f75432',
    color: '#ffffff',
  },
  {
    id: 9,
    value: '500',
    bgColor: '#f7bd02',
    color: '#ffffff',
  },
  // {
  //     id: 10,
  //     value: '1000',
  //     bgColor: '#c92729',
  //     color: '#ffffff',
  // },
]);

const state = reactive({
  gift: 1,
  gotValues: false,
  showAlert: '',
  alertMessage: '',
  logo: {
    width: 100,
    height: 120,
    src: require('./hasanmisbah.png'),
    audioSrc: require('./audio.mp3')
  },
});

const initialFormValue = {
  name: '',
  phone: '',
  amount: '',
  submitted: false,
};

const validationRules = {
  name: [
    (value) => !!value || 'Name is required',
    (value) => (value && value.length >= 3) || 'Name must be more than 3 characters',
  ],

  phone: [
    (value) => !!value || 'Phone is required',
    (value) => (value && value.length >= 11) || 'Phone must be more than 11 characters',
    (value) => {
      const regex = /^(\+88|01)?\d{11}$/;
      return regex.test(value) || 'Phone must be valid';
    }
  ],
};

const form = reactive({
  ...initialFormValue,
  submitted: false,
  errors: {},
  validate,
  setFieldValue,
  reset: resetForm,
  submit: formSubmit,
  feel: feelFormData
});

function resetForm(){
  for(let key in form){
    form[key] = initialFormValue[key];
  }
}

function validate () {

  form.errors = {};

  Object.keys(form).forEach((key) => {
    const rules = validationRules[key];

    if (!rules) {
      return;
    }

    const value = form[key];

    for(let i = 0; i < rules.length; i++){

      const rule = rules[i];
      const result = rule(value);

      if(result !== true){
        form.errors[key] = result;
        break;
      }
    }
  });
}

function formSubmit(cb){

  const data = {
    name: form.name,
    phone: form.phone,
    amount: form.amount,
    payload: state.gotValues?.value
  };

  return cb(data);
}

function setFieldValue (field, value) {
  form[field] = value;
}

function feelFormData(data){
  for(let key in data){
    form[key] = data[key];
  }
}

const hasError = () => {
  return Object.keys(form.errors).length > 0;
};

const submitForm = (e) => {

  e.preventDefault();

  // check if form is already submitted
  const previous = previousSubmission();

  if(!isEmpty(previous)){
    form.feel(previous);
    state.showAlert = 'error';
    state.alertMessage = 'You have already submitted your form.';
    return;
  }

  form.validate();

  if(hasError()) {
    return;
  }

  form.submit(async (value) => {
    try {

      await firestore.create('users', value);
      state.showAlert = 'success';
      state.alertMessage = 'You will get your salami soon.';
      storage.set('user', value);

    }catch (e) {

      state.showAlert = 'error';
      state.alertMessage = 'Something went wrong. Please try again.';
    }
  });
};

const previousSubmission = () => {
  return storage.get('user', null);
};

const randomGift = () => Math.floor(Math.random() * 4) + 2;

const done = (params) => {
  state.gotValues = params;
  form.setFieldValue('amount', params.value);
  wheel.value.clicked = false;
};

const spinTheWheel = async () => {

  // const userSubmission = previousSubmission();
  //
  // if(userSubmission !== null) {
  //   console.log({ form, userSubmission });
  //   alert('You have already submitted your information.');
  //   return;
  // }

  //resetWheel();

  state.gift = randomGift();

  setTimeout(() => {
    wheel.value.spin();
  }, 100);
};

const resetWheel = () => {
  state.gotValues = false;
  state.gift = 1;
};

const closeModal = () => {
  state.gotValues = false;
  state.showAlert = '';
  state.alertMessage = '';
};

watch(() => form.phone, (value, previousValue) => {
  // check if input is number
  if(isNaN(Number(value))) {
    form.phone = previousValue;
    return;
  }
});

</script>

<style>
*, *:before, *:after {
    margin: 0;
    padding: 0;
}

body {
    background: #555555 url('./bd-money.webp') center no-repeat;
    background-size: cover;
    position: relative;
}

body:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #000;
    opacity: 0.7;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.wheel_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wheelholder image {
    cursor: pointer;
}

#wheel svg {
    font-size: 30px;
}

.wheel_wrap h1 {
    color: #fff;
    font-size: 35px;
    margin-top: 20px;
}

.result-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgb(0 0 0 / 65%);
    overflow: scroll;

}

.result {
    width: 400px;
    height: 400px;
    z-index: 999;
    background: #fff;
    border-radius: 4px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.result h1 {
    color: #555;
}

.result button {
    padding: 6px 11px;
    border-radius: 2px;
    box-shadow: none;
    border: 1px solid #ddd;
    font-size: 16px;
    cursor: pointer;
    margin: 14px 0 0;
}


.has-fixed {
    height: 100%;
    width: 100%;
    overflow: hidden;
    max-height: 100vh;
}


.result-form {
    max-width: 95%;
    overflow: scroll;
}
</style>
