import { db as firebaseApp } from '@/firebase/config';
import firestore from 'firebase/compat/firestore';
export const useLocalStorage = (asJson) => {
  const get = (key, initialValue) => {
    const storage = localStorage.getItem(key);
    return storage ? asJson ? JSON.parse(storage) : storage : initialValue;
  };

  const set = (key, value) => {
    const data = asJson ? JSON.stringify(value) : value;
    localStorage.setItem(key, data);
    return data;
  };

  const remove = (key) => localStorage.removeItem(key);

  const clear = () => {
    localStorage.clear();
  };

  const storage = {
    get,
    set,
    remove,
    clear
  };

  return { storage };
};


export const useFirestore = (collection) => {

  const db = firebaseApp;

  // const get = async (id) => {
  //   const doc = await db.collection(collection).doc(id).get();
  //   return doc.exists ? doc.data() : null;
  // };

  // const getAll = async () => {
  //   const snapshot = await db.collection(collection).get();
  //   return snapshot.docs.map((doc) => doc.data());
  // };

  // const add = async (data) => {
  //   const docRef = await db.collection(collection).add(data);
  //   return docRef.id;
  // };

  // const update = async (id, data) => {
  //   await db.collection(collection).doc(id).update(data);
  // };
  //
  // const remove = async (id) => {
  //   await db.collection(collection).doc(id).delete();
  // };

  const create = async (collection, data) => {
    const docRef = await db.collection(collection).add(data);
    return docRef.id;
  };

  const firestore = {
    create
  };

  return { firestore };
};
