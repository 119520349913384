import Firebase from 'firebase/compat';
const firebaseConfig = {
  apiKey: 'AIzaSyBOznKhFZoc7BcnwaEcswGViEqlsnY-rnk',
  authDomain: 'salami-28338.firebaseapp.com',
  projectId: 'salami-28338',
  storageBucket: 'salami-28338.appspot.com',
  messagingSenderId: '925822418292',
  appId: '1:925822418292:web:fde44195c8cea17a34c021'
};
// Initialize Firebase
const app = Firebase.initializeApp(firebaseConfig);
const db = Firebase.firestore(app);

export { db };
